
import { defineComponent, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  setup() {
    const menusData = [{ name: '我的订单', url: '/personal/order' }]
    const router = useRouter()
    const route: any = useRoute()
    const activeUrl = ref(route.path)
    const menus = ref(menusData)
    const toUrl = (url: string) => {
      activeUrl.value = url
      router.push({ path: url })
    }

    return {
      toUrl,
      menus,
      activeUrl
    }
  }
})
