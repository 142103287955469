<template>
  <div class="personal">
    <div class="personal_ct main_width">
      <div class="personal_ctL">
        <dl>
          <dt class="personal_ctL-title">个人中心</dt>
          <dd
            class="personal_ctL-item"
            :class="{ active: activeUrl === item.url }"
            v-for="(item, index) in menus"
            :key="index"
            @click="toUrl(item.url)"
          >
            {{ item.name }}
          </dd>
        </dl>
      </div>
      <div class="personal_ctR">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  setup() {
    const menusData = [{ name: '我的订单', url: '/personal/order' }]
    const router = useRouter()
    const route: any = useRoute()
    const activeUrl = ref(route.path)
    const menus = ref(menusData)
    const toUrl = (url: string) => {
      activeUrl.value = url
      router.push({ path: url })
    }

    return {
      toUrl,
      menus,
      activeUrl
    }
  }
})
</script>

<style lang="scss" scoped>
.personal {
  margin-top: 103px;
  padding: 34px 0 79px;
  &_ct {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &_ctL {
    width: 210px;
    margin-right: 24px;
    padding: 20px 0;
    text-align: center;
    flex: 0 0 auto;
    background: #ffffff;
    box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.04);
    border-radius: 7px;

    &-title {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #555555;
      padding-bottom: 10px;
    }

    &-item {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      padding: 10px 0;
      color: #555555;
      cursor: pointer;

      &.active {
        color: #c8a260;
      }
    }
  }
  &_ctR {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.04);
    border-radius: 7px;
  }
}
</style>
